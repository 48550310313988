<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/liveManage' }">直播管理</el-breadcrumb-item>
      <el-breadcrumb-item>{{ title }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form ref="form" :model="form" label-width="80px" style="margin-top: 20px;width: 60%;">
      <el-form-item label="标题">
        <el-input v-model="form.title"></el-input>
      </el-form-item>
      <!-- <el-form-item label="排序" style="text-align: left;">
        <el-input v-model="form.sort"></el-input>
        <span style="font-size: 12px;color: #C0C4CC;">数字越大，越靠前</span>
      </el-form-item> -->
      <!-- <el-form-item label="开始时间">
        <el-date-picker
          style="float: left;"
          v-model="form.startTime"
          type="datetime"
          placeholder="选择开始时间"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item>
      <p class="tips">开始时间应大于当前时间</p>
      <el-form-item label="结束时间">
        <el-date-picker
          style="float: left;"
          v-model="form.endTime"
          type="datetime"
          placeholder="选择结束时间"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item> -->
      <el-form-item label="直播人">
        <el-input v-model="form.agent"></el-input>
      </el-form-item>
      <!-- <el-form-item label="录播地址">
        <el-input v-model="form.recUrl"></el-input>
      </el-form-item>
      <el-form-item label="直播地址">
        <el-input v-model="form.liveUrl"></el-input>
      </el-form-item> -->
      <el-form-item label="屏幕类型" style="text-align:left;">
        <el-select v-model="form.screenType" clearable placeholder="请选择">
          <el-option v-for="item in screenTypeoption" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="播放时长" style="text-align:left;">
        <el-input-number v-model="form.playTime" :min="10" :step="10" style="margin-right:20px;"></el-input-number>分钟
      </el-form-item>
      <el-form-item label="封面">
        <upload :src.sync="form.thumb"></upload>
      </el-form-item>
      <el-form-item label="描述">
        <el-input type="textarea" :rows="5" v-model="form.desc"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">保存</el-button>
        <el-button @click="cancel">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
// var Liveserve = "http://192.168.110.220:20210/";
var Liveserve = "https://live-server.51xk.info/";
import upload from "../components/upload";
export default {
  components: {
    upload,
  },
  data() {
    return {
      type: "",
      liveid: "",
      title: "添加直播",
      form: {
        title: "",
        // startTime: "",
        // endTime: "",
        desc: "",
        agent: "",
        thumb: "",
        // recUrl: "", //推流地址/录播地址
        // liveUrl: "", //播流地址/直播地址
        // sort: "", //排序
        userId: "0",
        userName: "但行好事",
        headImg: "http://cdn.51xk.info/images/2022/02/22/100213794191.jpg",
        screenType: "",
        playTime: "60", //播放时长
      },
      screenTypeoption: [
        { id: 1, name: "横屏" },
        { id: 0, name: "竖屏" },
      ],
    };
  },
  mounted() {
    this.type = this.$route.query.type;
    if (this.$route.query.id) {
      this.title = "修改直播";
      this.liveid = this.$route.query.id;
      this.getdetail();
    }
  },
  methods: {
    getdetail() {
      this.axios
        .get(Liveserve + "api/Live/get_live?id=" + this.liveid)
        .then((res) => {
          if (res.data.code == 1) {
            this.form = res.data.result;
          } else {
          }
        });
    },
    cancel() {
      this.$router.push({ path: "/liveManage" });
    },
    onSubmit() {
      let url, str;
      if (this.type == "bj") {
        url = "api/Live/edit";
        this.form.id = this.liveid;
        str = "修改成功";
      } else {
        url = "api/Live/add_with_play_time";
        str = "添加成功";
      }
      this.axios
        .post(Liveserve + url, this.qs.stringify(this.form))
        .then((res) => {
          console.log(res);
          if (res.data.code == 1) {
            this.$message({
              message: str,
              type: "success",
            });
            this.$router.push({ path: "/liveManage" });
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        })
        .catch((res) => {});
    },
  },
};
</script>
<style>
.tips {
  text-align: left;
  padding-left: 80px;
  font-size: 12px;
  color: #b2b2b2;
}
</style>
